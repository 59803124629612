:root {
  --space: 4px;
}

.carousel-section {
  padding-left: calc(4*var(--space));
  padding-right: calc(4*var(--space));
}

@media(min-width: 768px) {
  .carousel-section {
    padding-left: calc(10*var(--space));
    padding-right: calc(10*var(--space));
  }
}

@media(min-width: 1325px) {
  .carousel-section {
    padding-left: calc(22*var(--space));
    padding-right: calc(22*var(--space));
  }
}